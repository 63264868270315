@import "../variables";

.auth-wrapper {
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
  background-color: $white;
}

.auth-logo {
  width: 70px;
  height: 26px;
  margin: 35px 0 0 40px;
}

@mixin auth-background($image) {
  min-height: 100vh;
  background: $primary url("#{$image}") no-repeat left top;
  box-sizing: border-box;
  background-size: cover;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.register-background {
  @include auth-background("../../../assets/images/pngs/getStarted/customer.png");
}

.success-background {
  @include auth-background("../../../assets/images/pngs/getStarted/driver.png");
}


.auth-form {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px 20px;
}

@media (max-width: 991.9px) {
  .auth-wrapper {
    background-color: $primary;
    padding: 20px;
    height: 100%;
  }

  .auth-form-wrapper {
    border: 1px solid black;
    border-radius: 10px;
    padding: 30px 20px;
    background-color: white;
  }
}

@media (min-width: 500px) {
  .auth-form {
    &-wrapper {
      width: auto;
    }
  }
}
