@import "../variables";

.dark-theme {
  background: #1d1d1d !important;
  color: $white !important;
}

.light-theme {
  background: #FAFCFE !important;
  color: $text-color !important;
}

.body {
  &-light {
    @extend .light-theme;
  }

  &-dark {
    @extend .dark-theme;

    .text-white, .btn-close {
      color: $white !important;
    }

    footer {
      a,
      .text-black {
        color: $white;
      }
    }
  }
}

/* BACKGROUND */
.bg-dark {
  background-color: #232323 !important;
}

.bg-light-blue {
  background-color: #F6F5FF;
}

.bg-lilac {
  background-color: #EBEDF9;
}

.bg-light-lilac {
  background-color: #FEF8F6;
}

.bg-light-orange {
  background-color: #FBE7D0;
}

.bg-lighter-orange {
  background-color: #FDF5F2;
}

.bg-orange {
  background-color: $orange;
}

.bg-light-yellow {
  background-color: #FDF8F2;
}

.bg-lighter-yellow {
  background-color: #FFF8EB;
}

.bg-primary-blue {
  background-color: #2D3892;
}

.bg-grey {
  background-color: #495057;;
}


.border-orange {
  border: 1px solid $orange;
}

.border-blue {
  border: 1px solid #2D3892;
}

.border-white {
  border: 1px solid white;
}

/* COLOR */

.text-primary-darker {
  color: #2D3892
}

.text-dark {
  color: $dark;
}

.text-darker {
  color: $text-color;
}

.text-medium {
  color: $medium;
}

.text-orange {
  color: $orange;
}

.text-indigo {
  color: $indigo;
}

.text-success {
  color: #27AE60 !important;
}

.text-success-dark {
  color: #219653;
}

.text-office-brown-700 {
  color: $office-brown-700;
}
