$header-height: 75px;
$text-color: #232323;

/*BRAND COLOR VARIABLE*/
$primary: #2c3891;
$secondary: #3c91e6;
$info: #FFAB00;
$success: #3fe877;
$warning: #d48e00;
$orange: #D95525;
$danger: #CB1A14;
$light: #f3f3fb;
$black: #000000;
$white: #ffffff;
$dark: #333333;
$darker: #181818;
$medium: #808080;
$indigo: #1C2251;
$pink: #ffc0cb;
$purple: #f65ef6;

/*primary variables*/
$primary-900: #0f1330;
$primary-800: #161c49;
$primary-700: #1d2561;
$primary-600: #252f79;
$primary-500: #4f59a3;
$primary-400: #727ab6;
$primary-200: #969cc8;
$primary-100: #b9bdda;
$primary-50: #d5d7e9;
$primary-5: #e7e8f5;

/*gray variables*/
$gray-800: #1D2739;
$gray-500: #667185;
$gray-400: #98A2B3;
$gray-100: #F0F2F5;
$gray-50: #F9FAFB;
$gray-5: #E0E0E0;

/*neutral variables*/
$black-3: #828282;
$black-2: #808080;

/*Office brown variable*/
$office-brown-700: #645D5D;

/*THEME VARIABLE*/
$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "medium": $medium,
  "success": $success,
  "info": $info,
  "warning": $warning,
  "danger": $danger,
  "light": $light,
  "dark": $dark,
  "darker": $darker,
  "orange": $orange,
  "indigo": $indigo,
  "pink": $pink,
  "purple": $purple,
  "black-3": $black-3,
);
