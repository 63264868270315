@import "../variables";

.nav-pills {
  .nav-link {
    margin: 5px 15px 0 0;
    @extend .text-medium;
    line-height: 20px;
    font-size: 14px;
    font-weight: 500;
    padding: 0;
    align-items: center;
    vertical-align: middle;

    &.active,
    .show > & {
      padding: 2px 8px;
      font-weight: 600;
      border-radius: 10px;
      background: rgba($primary, 0.16);
      color: $primary !important;
    }
  }
}

.nav-underline {
  position: relative;
  margin-left: 30px;

  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: -30px;
    bottom: 0;
    width: 4px;
    height: 100%;
    border-radius: 20px;
    background: rgba($primary, 0.16);
    transition: background-color 0.5s ease-in-out;
  }

  .nav-item {
    position: relative;

    .nav-link {
      padding: 7px;
      color: $dark;
      display: flex;
      align-items: center;
      border: none;
      cursor: pointer;
      font-size: 24px;
      font-weight: 500;
      line-height: 34.8px;
      transition: color 0.3s ease;

      &:hover {
        color: lighten($primary, 5);
      }

      &.active {
        font-weight: 700;
        color: $primary;

        &:before {
          content: "";
          display: block;
          position: absolute;
          top: 0;
          left: -30px;
          width: 4px;
          height: 100%;
          border-radius: 20px;
          background-color: $primary;
          transition: background-color 0.5s ease-in-out;
        }
      }
    }
  }
}

@media (max-width: 500px) {
  .nav-pills {
    .nav-link {
      font-size: 14px;
      line-height: 20px;
    }
  }
  .nav-underline {
    margin-right: 20px;
    margin-left: 35px;

    .nav-item {
      .nav-link {
        font-size: 18px;
        font-weight: 400;
        line-height: 26.1px;
        color: $medium;
      }
    }
  }
}
