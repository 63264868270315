@import "../variables";

.main-body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100vw;
  overflow-x: hidden;
}

.page-layout {
  @extend .flex-column;
  display: flex;
  flex: 1 0 auto;
  width: 100vw;
  overflow-x: hidden;
}

.main-wrapper {
  display: flex;
  flex: 1 0 auto;
  height: 100vh;
}

.main-content {
  flex: 1;
  overflow-y: auto;
  margin: 100px 10px 0;

  &::-webkit-scrollbar {
    display: none;
    -ms-overflow-style: none
  }
}

.side-content {
  width: 320px;
  display: block;
  overflow-y: auto;
  margin: 110px 10px 0;

  &::-webkit-scrollbar {
    display: none;
  }
}

/* END OF CONTAINERS*/

.app-store-banner {
  margin: 130px auto 100px;

  .inner-container {
    border-radius: 20px;
    background-color: $primary;
    padding: 0 60px;
  }

  .banner-image {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }
}

.image-container {
  margin-top: -40px;
}

.box-shadow {
  box-shadow: 1px 3px 12px #dfe0e0;
}

.breadcrumb {
  &-item {
    & +&::before {
      content: ">";
      color: $medium;
      padding: 0 10px;
    }

    a {
      text-align: center;
      color: $medium;
    }

    &.active {
      a {
        font-weight: 700;
        color: $primary;
      }
    }
  }
}

.blue-arch {
  position: relative;
  left: 60%;
}

.circle-thumbnails-container {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  gap: 20px;
  margin-top: 30px;
  max-width: 1200px;
}

.circle-thumbnail {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  flex: 1;
}

.home-thumbnail {
  position: relative;

  &:not(:last-child)::after {
    position: absolute;
    content: "";
    top: 50%;
    left: 40%;
    right: 60%;
    width: 30px;
    height: 1px;
    background-color: #D8DBF3;
    transform: translateX(-50%);
  }
}

.circle-thumbnail-icon {
  font-size: 14px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.circle-thumbnail:last-child::after {
  display: none;
}

.collabsible-text-container {
  display: flex;
  margin-top: 49px;
  flex-direction: column;
  font-size: 24px;
  color: #333;
  font-weight: 700;
  letter-spacing: 0.24px;
  line-height: 31px;
}

.crown-position {
  position: relative;
  bottom: 40px;
  left: 2px;
}

.culture-info {
  background-color: #f0f6ff;
  display: flex;
  margin-top: 150px;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 80px 60px;
}

.cursor-pointer {
  cursor: pointer;
}

.grid-white {
  background: url("../../images/svgs/heroSvgs/girdWhite.svg");
}

@mixin hero($image) {
  @extend .d-flex;
  @extend .flex-column;
  @extend .justify-content-center;
  @extend .align-items-center;
  padding-top: 120px;
  max-width: 1200px;
  margin: 0 auto;
  background: transparent url("#{$image}") no-repeat center;
  h2 {
    line-height: 80px;
  }
}

.driveHub-hero {
  @include hero("../../images/svgs/heroSvgs/gridBlack.svg");

  .hero_container {
    width: 500px;
  }
}

.moveByTruq-hero {
  @include hero("../../images/svgs/heroSvgs/gridBlack.svg");

  .hero_container {
    width: 800px;
  }
}

.siju-hero {
  @include hero("../../images/svgs/heroSvgs/gridBlack.svg");

  .hero_container {
    width: 800px;
  }
}

.truqPay-hero {
  @include hero("../../images/svgs/heroSvgs/gridBlack.svg");
  margin-bottom: 50px;
}

.home-hero {
  background: #1d1d1d url("../../images/svgs/heroSvgs/home-hero.svg") no-repeat center;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  color: #fff;
  text-align: center;
  padding: 70px 20px 60px;

  h1 {
    font-size: 80px;
    font-weight: 700;
    line-height: 115px;
  }

  h1, h2 {
    max-width: 700px;
  }
}

.hero-description {
  letter-spacing: 0.16px;
  margin: 25px auto 40px;
  max-width: 550px;
  width: 100%;
  color: $medium;
}

.icon-avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #2D3892;
  font-size: 14px;
  font-weight: 700;

  &-mission {
    width: 40px;
    height: 40px;
    padding: 8px;
    border-radius: 50%;
  }

  &-vision {
    width: 50px;
    height: 50px;
    padding: 9px;
    border-radius: 50%;
  }
}

.image-frame {
  display: inline-flex;
  align-items: center;
}

.curved-images {
  border-radius: 20px;
}

.circle {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  overflow: hidden;
  margin-left: -12px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $white;
  border: 2px solid $primary;
}

.circle:first-child {
  margin-left: 0;
}

.circle img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.circle.plus {
  background-color: $primary;
  border: none;
  color: $white;
  font-size: 22px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}

.circle.plus span {
  display: block;
}

.newsletter {
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  background-color: #292929;
  display: flex;
  margin: 150px auto 80px;
  width: 771px;
  max-width: 100%;
  color: #fff;
  padding: 40px 60px;
}

@mixin bg($image, $position) {
  position: relative;
  background-image: url($image);
  background-size: cover;
  background-position: $position;
  height: 350px;
  border-radius: 16px;
  > * {
    position: relative;
    z-index: 3;
  }
}

@mixin overlay($color) {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $color;
  z-index: 2;
  border-radius: 16px;
  content: '';
}

.driveHub-bg {
  @include bg("https://storage.googleapis.com/public_storage_truq/website/home_driver_intransit.jpg", 50% 15%);

  &::before {
    @include overlay(rgba(216, 219, 243, 0.7));
  }
}

.moveByTruq-bg {
  @include bg("https://storage.googleapis.com/public_storage_truq/website/home_couple_unpacking.jpg", center);

  &::before {
    @include overlay(rgba(248, 221, 211, 0.77));
  }
}

.truqPay-bg {
  @include bg("../../images/pngs/truQPay/solution1.png", center);

  &::before {
    @include overlay(rgba(251, 231, 208, 0.8));
  }
}

.siju-bg {
  @include bg("https://storage.googleapis.com/public_storage_truq/website/home_woman_taking_phone_call.jpg", center);

  &::before {
    @include overlay(rgba(44, 56, 145, 0.65));
  }
}

@mixin people($image, $position) {
  position: relative;
  background-color: $primary;
  background-image: url($image);
  background-size: cover;
  background-position: center;
  height: 300px;
  width: 100%;
  border-radius: 16px;
  object-fit: fill;
  background-repeat: no-repeat;
}

.people-1 {
  @include people("https://storage.googleapis.com/public_storage_truq/website/about_us_people_1_wuibsfkj.jpg", 0% 55%);
}

.people-2 {
  @include people("https://storage.googleapis.com/public_storage_truq/website/about_us_people_2_sbsfhd.jpeg", center);
}

.people-3 {
  @include people("https://storage.googleapis.com/public_storage_truq/website/about_us_people_3_sjdgdh.jpeg", center);
}

.people-4 {
  @include people("https://storage.googleapis.com/public_storage_truq/website/about_us_people_4_adjhdfbj.jpeg", center);
}

.people-5 {
  @include people("https://storage.googleapis.com/public_storage_truq/website/about_us_people_5_egtsoang.jpg", 0% 55%);
}

.productLogo-container {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.scroll-to-top {
  @extend .position-fixed;
  @extend .d-flex;
  @extend .justify-content-center;
  @extend .align-items-center;
  @extend .text-white;
  @extend .box-shadow;
  bottom: 30px;
  right: 30px;
  width: 50px;
  height: 50px;
  background-color: $primary;
  border: none;
  border-radius: 50%;
  font-size: 24px;
  cursor: pointer;
  z-index: 1030;
  transition: opacity 2.5s ease-in-out;

  &:hover {
    background-color: lighten($primary, 10px);
  }
}

.slide {
  width: 30%;
  height: 320px !important;
  float: right;

  &-inactive {
    margin-top: 40px !important;
  }
}

.stat {
  background-color: #fef8f6;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  padding: 30px 0;

  &-item {
    justify-content: center;
    display: flex;
    flex-direction: column;
    border-right: 0.4px solid #8A8A8A !important;
    width: 400px;
    padding: 0 10px;
    margin: 0 20px;
    text-align: center;
  }
}

.subscribe-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 400px;
  padding: 3px;
  border-radius: 5px;
  border: 1px solid white;
}

.subscribe-input {
  width: 300px;
  padding: 10px 0 10px 15px;
  border: none;
  background-color: $primary;
  color: white;
  font-size: 15px;
  font-weight: 400;
}

.subscribe-input::placeholder {
  color: white;
  font-weight: normal;
}


.subscribe-input:focus {
  outline: none;
}

.get-started-button:focus {
  outline: none;
}

.truq_app {
  background-color: #F6ECDA;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
}

.move_by_truq {
  background-color: #F6F5FF;
  display: flex;
  align-items: end;
  padding: 0 0 30px 80px;

  Image {
    height: 90%;
  }
}

.siju_by_truq {
  background-color: #D6E4F8;
  display: flex;
  align-items: end;
  padding: 0 0 40px 92px;

  Image {
    height: 90%;
  }
}


.success-message {
  display: flex;
  position: relative;
  top: 30px;
  left: 10px;
  background-color: #FBF3E9;
  padding: 10px 15px;
  border-radius: 8px;
  align-items: center;
  box-shadow: 0 1.61px 1.61px 0 rgba(189, 189, 189, 0.08);

  &-transfer {
    position: absolute;
    top: 160px;
    padding: 5px 10px;
  }

  &-renewal {
    position: absolute;
    top: 80px;
    left: 400px;
    background-color: #FCDEBC;
    padding: 5px 10px;
  }
}

.success-header {
  font-size: 10px;
  font-weight: 400;
  line-height: 11.84px;
}

.success-text {
  font-size: 13px;
  font-weight: 700;
}

.points-notification {
  display: flex;
  justify-content: space-between;
  position: relative;
  top: 125px;
  left: 145px;
  background-color: #EBEDF9;
  border-radius: 8px;
  padding: 8px 5px;
  box-shadow: 0 1.99px 1.99px 0 rgba(189, 189, 189, 0.08);

  &-points {
    position: absolute;
    top: 0;
    left: 860px;
    padding: 5px 10px;
  }

  &-topUp {
    position: absolute;
    top: 380px;
    left: 780px;
    padding: 5px 10px;
  }
}

.points-header {
  font-size: 12px;
  font-weight: 400;
  line-height: 14.62px;
}

.points-text {
  font-size: 16px;
  font-weight: 700;
}

.topUp-header {
  font-size: 16px;
  font-weight: 700;
  line-height: 14.62px;
}

.topUp-text {
  font-size: 12px;
  font-weight: 400;
  line-height: 14.62px;
}

/* Steps Section Styles */

.step {
  margin: 30px auto;
  display: flex;
  flex-direction: column;
  text-align: left;
  max-width: 217px;
  width: 100%;

  &-img-container {
    width: 100%;
    height: 270px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      margin: 4px auto;
    }
  }

  &-number {
    background: #F0F6FF;
    font-weight: 700;
    font-size: 14px;
    width: 40px;
    height: 40px;
    margin: 15px 0 10px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.slick-prev:before,
.slick-next:before {
  font-family: none !important;
  border-radius: 50% !important;
  padding: 15px !important;
  font-size: 18px !important;
  font-weight: 700;
  opacity: 1 !important;
  background: $primary;
}

.slick-next {
  right: 220px !important;
}

.slick-prev {
  left: 190px !important;
  z-index: 1;
}

ul.tabs-list {
  margin-top: 20px;
  padding-left: 0;
  margin-left: 25px;

  & > li {
    @extend .text-black-3;
    margin-bottom: 16px;

    &::marker {
      color: #F3B163;
      font-size: 30px;
    }
  }
}

.success-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 20px;
  padding: 10px 20px;
  box-shadow: 0 2px 2px 0 rgba(66, 66, 66, 0.1);
}


.custom-mobile-layout {
  display: flex;
  justify-content: space-between;

}


.custom-mobile-layout {
  display: flex;
  justify-content: space-between;

}

.image:hover {
  transform: scale(1.1);
}

.scale-effect {
  transform: scale(1.1); /* Scale the image */
  transition: transform 0.06s ease-in-out; /* Smooth transition */
}

// .image-visible {
//   opacity: 1;
//   transform: translateY(0);
// }


@media (max-width: 1400px) {
  .driveHub-hero, .siju-hero, .truqPay-hero {
    h2 {
      line-height: 85px;
    }
  }
}

@media (max-width: 1040px) {
  .slick-next {
    right: 180px !important;
  }

  .slick-prev {
    left: 150px !important;
  }

  .success-message {
    &-transfer {
      top: 220px;
      left: 0;
    }

    &-renewal {
      left: 370px;
    }

  }

  .points-notification {

    &-points {
      left: 760px;
    }

    &-topUp {
      top: 270px;
      left: 680px;
    }
  }

  .siju-hero, .moveByTruq-hero {
    .hero_container {
      width: 600px;
    }
  }

  .driveHub-hero {
    .hero_container {
      width: 400px
    }
  }

}


@media (max-width: 991px) {
  .app-store-banner {
    margin: 80px auto 30px;
    max-width: 550px;

    .inner-container {
      padding: 70px 30px 0;
    }
  }

  .career-article {
    text-align: center;
  }

  .collabsible-text-container {
    max-width: 100%;
    margin-top: 10px;
  }

  .culture-info {
    margin-top: 100px;
  }

  .home-hero {
    max-width: 100%;

    h1 {
      max-width: 100%;
      margin-top: 40px;
      font-size: 40px;
      line-height: 55px;
    }
  }

  .inner-content {
    padding: 100px 20px 20px;
  }

  .newsletter {
    margin: 70px auto 50px;
  }

  .newsletter,
  .culture-info {
    padding: 60px 30px;
  }

  .stat-item {
    width: 220px;
  }

  .upgrade-option {
    white-space: initial;
  }

  .circle-thumbnails-container {
    flex-direction: column;
    align-items: center;
    max-width: 320px;
    margin: 0 auto;
  }

  .circle-thumbnail {
    margin: 15px 20px;
  }

  .home-thumbnail {
    position: relative;

    &:not(:last-child)::after {
      position: absolute;
      content: "";
      top: 50%;
      left: 0;
      width: 1px;
      height: 25px;
      padding: 5px 0;
      background-color: #D8DBF3;
      transform: translateY(-50%);
    }
  }


  .truq_app {
    padding-top: 20px;

    Image {
      height: 90%;
      width: 70%;
    }
  }

  .move_by_truq, .siju_by_truq {
    padding: 0 0 140px 20px;

    img {
      max-width: 330px !important;
    }
  }

  .success-message {
    &-transfer {
      top: -20px;
      left: 430px;
    }

    &-renewal {
      display: none;
    }

  }

  .points-notification {
    top: 160px;
    left: 84px;

    &-points {
      top: 30px;
      left: 50px;
    }

    &-topUp {
      display: none;
    }
  }

  .success-card {
    box-shadow: none;
  }

  .slick-next {
    right: 198px !important;
  }

  .slick-prev {
    left: 170px !important;
  }

  .image-container {
    margin-top: 40px;
  }

}


@media (max-width: 768px) {
  .side-content {
    display: none;
  }

  .main-content {
    width: 100%;
  }

  .driveHub-hero, .siju-hero, .truqPay-hero {
    h2 {
      line-height: 60px;
    }
  }

  .home-hero {
    margin-top: 30px;
  }

  .hero-description {
    margin-top: 10px;
    padding: 10px 20px;
  }

  .container {
    max-width: 740px;
  }

  .slick-next {
    right: 170px !important;
  }

  .slick-prev {
    left: 150px !important;
  }

  .blue-arch {
    width: 125px;
  }

  .custom-mobile-layout {
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .custom-mobile-layout .logo-item {
    flex: 0 0 48%;
  }

  .success-message {
    &-transfer {
      padding: 5px 8px;
      top: -60px;
      left: 250px;
    }
  }

  .points-notification {

    &-points {
      top: 10px;
      left: 10px;
    }
  }

  .points-text {
    font-size: 14px;
  }

  .siju-hero, .moveByTruq-hero {
    .hero_container {
      width: 500px;
    }
  }

  .driveHub-hero {
    .hero_container {
      width: 300px
    }
  }

}

@media (max-width: 470px) {
  .crown-position {
    position: relative;
    bottom: 24px;
    left: 8px;
  }

  .slick-prev:before,
  .slick-next:before {
    font-size: 14px !important;
  }

  .driveHub-hero, .siju-hero, .truqPay-hero {
    h2 {
      line-height: 55px;
    }
  }

  .slick-next {
    right: 25px !important;
  }

  .slick-prev {
    left: 0 !important;
  }

  .blue-arch {
    width: 80px;
  }

  .app-store-banner {
    margin: 60px 0 20px;
    max-width: 100%;

    .inner-container {
      padding: 40px 20px;
    }
  }

  .subscribe-container {
    width: 95%;
  }

  .subscribe-input {
    width: 80%;
    padding: 5px;
  }

  .get-started-button {
    padding: 5px;
  }

  .move_by_truq, .siju_by_truq {
    padding: 30px 0 20px 40px;
  }

  .success-message {
    margin-bottom: 10px;

    &-transfer {
      left: 100px;
    }
  }

  .siju-hero, .moveByTruq-hero {
    .hero_container {
      width: 300px;
    }
  }

  .image-container {
    margin-bottom: -40px;
  }
}

@media (max-width: 470px) {

}
