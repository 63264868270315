@import "../variables";


:root {
  --primary-bg-color: #2d3892;
  --primary-bg-hover: #2b3691;
  --primary-transparentcolor: rgba(45, 56, 146, 0.16);
  --primary-bg-border: #2D3892;
  --dark-theme: #0e0e23;
  --dark-body: #24243e;
  --dark-border: rgba(255, 255, 255, 0.15);
  --dark-color: #d0d0e2;
  --dark-primary: #6259ca;
  --indigo: #4b0082;
  --purple: #6f42c1;
  --pink: #f1388b;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: $white;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #2D3892;
  --secondary: #3C91E6;
  --success: #0F973D;
  --info: #FFAB00;
  --warning: #ffc107;
  --danger: #CB1A14;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --breakpoint-xxl: 1400px;
  --font-family-sans-serif: Neue Haas Grotesk Text Pro, -apple-system, Roboto, Helvetica, sans-serif !important;
  --font-family-monospace: Neue Haas Grotesk Text Pro, -apple-system, Roboto, Helvetica, sans-serif !important;
  --bs-heading-color: $medium;
}

.row {
  --bs-gutter-x: 1.2rem;
}

* {
  box-sizing: border-box;
  font-family: Neue Haas Grotesk Text Pro, -apple-system, Roboto, Helvetica, sans-serif !important;

  &::before,
  &::after {
    box-sizing: border-box;
  }
}

html {
  font-family: Neue Haas Grotesk Text Pro, -apple-system, Roboto, Helvetica, sans-serif !important;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}


body {
  margin: 0;
  padding: 0;
  font-family: Neue Haas Grotesk Text Pro, -apple-system, Roboto, Helvetica, sans-serif !important;
  font-size: 16px;
  letter-spacing: 0.16px;
  font-weight: 500;
  line-height: 1.26875;
  text-align: left;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;
  -webkit-text-size-adjust: none;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  -webkit-font-feature-settings: "liga" 0;
  font-feature-settings: "liga" 0;
  overflow-y: scroll;
  overflow-x: hidden;
  -ms-overflow-x: hidden;
  -ms-overflow-y: scroll;
  width: 100%;
  position: relative;
  scrollbar-width: none;
  -ms-overflow-style: none;
  color: $text-color !important;
}

a {
  font-style: normal;
  font-weight: 500;
  color: $primary;
  text-decoration: none;
}

p,
li {
  font: 400 16px/23px Neue Haas Grotesk Text Pro, -apple-system, Roboto, Helvetica, sans-serif;
  margin-bottom: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-style: normal;
  font-weight: 700;
  letter-spacing: -0.02em;
  margin: 0;
}

h1 {
  font-size: 60px;
  line-height: 75px;
}

h2 {
  font-size: 55px;
  line-height: 70px;
}

h3 {
  font-size: 42px;
  line-height: 57px;
}

h4 {
  font-size: 30px;
  line-height: 39.3px;
}

h5 {
  font-size: 22px;
  line-height: 30px;
}

h6 {
  font-size: 18px;
  line-height: 22.70px;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

[type="number"] {

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    height: auto;
  }
}


[hidden] {
  display: none !important;
}

img {
  vertical-align: middle;
  border-style: none;
  max-width: 100%;
  object-fit: cover;
}

.card {
  --bs-card-border-color: white;
}

.no-scrollbar {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

::-webkit-scrollbar-button {
  display: none;
}

::-webkit-scrollbar {
  width: 5px;
  background-color: transparent;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb,
::-webkit-scrollbar-button {
  border-radius: 3px;
  background: linear-gradient(to top right, $secondary, $dark);
  border: 1px solid $secondary;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover,
::-webkit-scrollbar-button:hover {
  width: 2px;
  background: $primary;
}

small {
  font-size: 11px;
  font-weight: lighter;
}

textarea {
  resize: none;
}


@media (max-width: 767px) {
  body {
    font-size: 14px;
    line-height: 1.06875;
  }

  h1 {
    font-size: 50px;
    line-height: 65.5px;
  }

  h2 {
    font-size: 40px;
    line-height: 58.9px;
  }

  h1 {
    font-size: 55px;
    line-height: 70px;
  }

  h2 {
    font-size: 45px;
    line-height: 55px;
  }

  h3{
    font-size: 28px;
    line-height: 35.3px;
  }

  h4 {
    font-size: 25px;
    line-height: 30.3px;
  }
}

@media (max-width: 470px) {
  h1 {
    font-size: 55px;
    line-height: 70px;
  }

  h2 {
    font-size: 45px;
    line-height: 55px;
  }

  h4 {
    font-size: 22px;
    line-height: 28px;
  }

  h5 {
    font-size: 20px;
    line-height: 28px;
  }
}

@media (max-width: 470px) {
  h1 {
    font-size: 50px;
    line-height: 60.04px;
  }

  h2 {
    font-size: 40px;
    line-height: 50px;
  }
}
