.accordion {
  width: 650px;
  max-width: 100%;

  &-title {
    font-size: 18px;
    font-weight: 500;
    margin: 0 20px;
  }

  &-border-start{
    width: 2px;
    height: 45px;
    margin: auto 0;
  }

  &-id {
    color: #2D3892;
    font-size: 16px;
    font-weight: 500;
  }

  &-body {
    color: #808080;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    padding: 20px !important;
    border: 1px solid $white;
    border-radius: 5px !important;
  }

  &-button {
    padding: 20px 20px;
    font-size: 16px;
    font-weight: 700;
    line-height: 20.16px;
    text-align: left;
    border: 0;
    overflow-anchor: none;

    &:focus,
    &:active {
      z-index: 3;
      border-color: transparent;
      outline: 0;
      box-shadow: transparent;
    }

    &:not(.collapsed) {
      color: var(--bs-accordion-active-color);
      background-color: transparent;
      box-shadow: none;
    }
  }

  &-item {
    outline: none;
    border: none;
    margin-bottom: 20px;
    border-radius: 5px;
  }
}



@media (max-width: 576px) {
  .accordion {
    width: 100%;

    &-id {
      padding-right: 3px;
    }

    &-button {
      padding: 20px 15px !important; 

      &.collapsed {
        padding: 0;
      }
    }
  }
}