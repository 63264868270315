.circle-thumbnail-with-icon-header {
  color: #333333;
  font-weight: 700;
  font-size: 17px;
  line-height: 22px;
}

.company-info-text {
  color: #898989;
  font-weight: 450;
  font-size: 13px;
  line-height: 20px;
}

.beliefs-text {
  color: #FFFFFF;
  font-weight: 400;
  font-size: 19px;
  line-height: 28px;
}

/* POSITION */
.line-spacing-05 {
  letter-spacing: 0.5px;
}


.text-justify {
  text-align: justify;
}

/* SIZE */
.font-size-9 {
  font-size: 9px;
}

.font-size-12 {
  font-size: 12px;
}

.font-size-14 {
  font-size: 14px;
  line-height: 19px;
}

.font-size-16 {
  font-size: 16px;
}

.font-size-18 {
  font-size: 18px;
  line-height: 25px;
}

.font-size-20 {
  font-size: 20px;
  line-height: 27.6px;
}

.font-size-23 {
  font-size: 23px;
  line-height: 28.13px
}

.font-size-30 {
  font-size: 30px;
}


/* WEIGHT */
.font-weight-400 {
  font-weight: 400;
}

.font-weight-700 {
  font-weight: 700;
}

.testimony-text{
  font-size: 14px !important;
  font-weight: 400;
  line-height: 25px;
}

.text-loop {
  display: inline-block;
  color: inherit;
  animation: fade 2s infinite;
}

@keyframes fade {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  10% {
    opacity: 0.7;
    transform: translateY(0);
  }
  90% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-10px);
  }
}

@media (max-width: 768px) {

.font-size-20{
  font-size: 14px;
  line-height: 16.56px;
}

.font-size-18{
  font-size: 16px;
  line-height: 18px;
}

.font-size-16 {
  line-height: 17.16px;
}

}
