footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: flex-end;
  background-color: #e8e8f8;
  bottom: 0;
  width: 100%;
  z-index: 99;
  padding: 15px 100px 10px;
  flex: 0 0 auto;

  .info-container {
    width: 100%;
    align-self: center;
    max-width: 1450px;
  }

  h5 {
    font-size: 20px;
    font-weight: 500;
  }

  h6 {
    font-size: 18px;
    font-weight: 700;
  }

  a {
    color: #808080;
    font-weight: 400;
    font-size: 16px;
    padding-bottom: 10px;
  }

  .footer-note {
    font-size: 14px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: -0.02em;
  }
}

.footer-links {
  @extend .d-flex;
  @extend .justify-content-between;
  @extend .flex-wrap;
  margin: 100px 0 70px;
  column-gap: 35px;
  row-gap: 25px;
}

@media (max-width: 870px) {
  footer {
    max-width: 100%;
    padding: 10px 50px;
  }

  .footer-links {
    margin: 70px 0;
  }

  .footer-note {
    display: block;
    text-align: start !important;
  }
}

@media (max-width: 500px) {
  footer {
    padding: 10px 30px;
  }

  .footer-links {
    margin: 40px 0;
  }

}
