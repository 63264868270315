/* HEIGHT */
.h-20 {
  height: 20px;
}

.h-45 {
  max-height: 45px;
}

.h-410 {
  height: 410px;
  max-width: 330px;
}

.h-270 {
  height: 270px;
}
/* PADDING */
.py-30 {
  padding: 30px 0 20px;
}

/* POSITION */
.fixed-content {
  max-height: 75%;
  position: fixed;
  overflow-y: scroll;
  overflow-x: hidden;
}

/* WIDTH */
.w-20 {
  width: 20px;
}

.w-120 {
  width: 120px !important;
}

.w-150 {
  width: 150px;
}

.w-35-p {
  width: 35%;
}

.w-170 {
  width: 130px;
}

.w-230 {
  max-width: 230px;
}

.max-w-320{
  max-width: 320px;
}

.w-299 {
  width: 299px;
}

.w-400{
  width: 400px;
}

.w-414 {
  width: 414px;
}

.w-470 {
  width: 470px;
}

.w-460 {
  max-width: 460px;
}

.w-507 {
  max-width: 507px;
}

.w-600 {
  max-width: 600px;
}

.w-720 {
  max-width: 720px;
}

.w-771 {
  max-width: 771px;
}

.w-1000 {
  max-width: 1000px;
}

.w-850 {
  max-width: 850px;
}

.mt--30 {
  margin-top: -30px;
}

.mb--80 {
  margin-bottom: -80px;
}

.my-50 {
  margin: 50px 0;
}

.mt-90 {
  margin-top: -50px;
}

.ms-240 {
  margin-left: 120px;
}

.me-130 {
  margin-right: 130px;
}

.h-300 {
  max-height: 300px;
}


@media (min-width: 2500px) {

  .ps-xxl-400 {
    padding-left: 400px !important;
  }

  .ms-xxl-300 {
    margin-left: 430px !important;
  }

  .mt-xxl-180 {
    margin-top: 180px !important;
  }

}


@media (min-width: 992px) {

  .w-lg-70-p {
    width: 70%;
  }

  .w-lg-60-p {
    width: 60%;
  }

  .w-lg-55-p {
    width: 55%;
  }

  .w-lg-45-p {
    width: 45%;
  }

  .w-lg-40-p {
    width: 40%;
  }

  .w-lg-30-p {
    width: 30%;
  }

  .ms-170 {
    margin-left: 170px;
  }

  .ms-240 {
    margin-left: 240px;
  }

  .ms-lg-90 {
    margin-left: 90px !important;
  }

  .ms-lg-200 {
    margin-left: 350px !important;
  }

  .mt--90 {
    margin-top: -90px;
  }

  .w-80 {
    width: 80px;
  }

  .w-170 {
    width: 170px;
  }

  .w-250 {
    width: 250px;
  }

  .w-500 {
    max-width: 500px;
  }

  .h-530 {
    height: 430px;
  }

  .mt-md-120 {
    margin-top: 120px !important;
  }
}

@media (min-width: 786px) {
  .w-945 {
    width: 945px;
  }

  /* MARGIN */

  .mt-md-50{
    margin-top: 50px !important;
  }

  .mt-md-70 {
    margin-top: 70px !important;
  }

  .my-md-70 {
    margin: 70px 0 !important;
  }

  .mt-md-100 {
    margin-top: 100px !important;
  }

  .mt-md-130 {
    margin-top: 130px !important;
  }

  .mt-md-150 {
    margin-top: 250px !important;
  }

  .mb-md-110 {
    margin-bottom: 110px !important;
  }

  .my-md-150 {
    margin: 150px 0 !important;
  }
}

@media (max-width: 470px) {
  .h-40 {
    height: 40px;
  }
}
