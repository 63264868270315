header {
  box-shadow: 0 0 10px rgba(46, 47, 50, 0.08);
}

header>nav {
  justify-content: space-between;
  display: flex;
  height: $header-height;
  width: 100%;
  gap: 20px;
  font-weight: 700;
  text-align: center;
  padding: 15px 50px;
  flex: 0 0 auto;


  .nav-link {
    color: #232323;
  }
}

.header-item-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 700px;
  max-width: 100%;
  max-height: 0;
  overflow: hidden;
  transition: all 0.5s;
  transform: translateX(0);

  &.show {
    max-height: 100vh;
    transition: all .75s;
    padding: 1.5rem 0;
  }

  &.hide {
    max-height: 0;
    transition: all .15s;
  }
}

.header {
  &-icon {
    background-color: #D8DBF3;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 45px;
    height: 45px;
    padding: 12px;

    img{
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }

  &-title {
    color: #333333;
    font-size: 14px;
    font-weight: 600;
  }

  &-subtitle {
    color: #808080;
    font-size: 12px;
    font-weight: 450;
  }

  &-actions {
    display: flex;
  }
}

.menu {
  display: none;
  float: right
}

.mobile-header {
  ul {
    @extend .d-flex;
    @extend .flex-column;
    @extend .row-gap-4;
    padding: 0 7px 10px;

    li {
      list-style-type: none;

      &:not(:last-child) {
        padding-bottom: 20px;
        border-bottom: 0.5px solid $medium;
      }

      h5 {
        font-size: 17px;
      }
    }
  }

  .button-group {
    @extend .d-flex;
    @extend .justify-content-center;
    @extend .gap-5;
    @extend .mt-3;
  }
}

.offcanvas {
  &-header {
    height: ($header-height + 9px) !important;
  }

  &-backdrop {
    z-index: 0;
  }
}

@media (max-width: 991px) {
  header>nav {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 15px 30px;
  }

  header .nav,
  .header-actions,
  .header-item-container {
    display: none;
  }

  .menu {
    margin-top: 5px;
    display: block;
  }
}
