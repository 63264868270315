@import "../variables";

.form-lightblue {
    color: #828282 !important;
    background-color: #EBEDF9 !important;

    &:disabled {
      color: $black-2 !important;
      background: #D8DBF3 !important;
    }
  }
    .was-validated .form-control:invalid,
.form-control.is-invalid {
  background-image: none;
}


.form-floating {
    label {
      font-size: 12px;
      font-weight: 400;
      line-height: 15px;
      letter-spacing: 0;
      text-align: left;
      color: $black-3;
      padding-top: 20px;
    }

    .textarea{
        height: 96px;
    }
}

.form-floating>.form-control:focus~label::after,
.form-floating>.form-control:not(:placeholder-shown)~label::after,
.form-floating>.form-select~label::after {
    background-color: transparent;
}

.form-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
}
