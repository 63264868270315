.btn {
  @extend .d-inline-block;
  height: 48px;
  width: fit-content;
  border-radius: 5px;
  font-size: 14px;
  letter-spacing: 0;
  gap: 10px;
  vertical-align: middle;
  user-select: none;
  padding: 8px 25px;
  border: 1px solid transparent;
  font-weight: 600;
  line-height: 1.5;
  text-align: center;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.btn-sm,
.btn-group-sm>.btn {
  height: 32px;
  font-size: 12px;
}

.btn-link {
  @extend .text-decoration-none
}

.btn-dark {
  color: $white;
  // border: 1px solid rgba(255, 255, 255, 0.6);
  background-color: rgb(29, 29, 29);

  &:hover {
    border: 1px solid rgba(255, 255, 255, 0.6);
    background-color: lighten(#333, 5px);
  }

  &:focus,
  &.focus {
    box-shadow: 0 0 0 0.2rem rgba(51, 51, 51, 0.5);
  }
}

.btn-white{
  color: $white;
    border: 1px solid rgba(255, 255, 255, 0.6);
    background-color: #333;
}

.btn-lighter {
  background-color: #FBE7D0;
  color: #2D3892;

  &:hover, &:active {
    border: 1px solid lighten(#FBE7D0, 10px);
    background-color: darken(#FBE7D0, 3px) !important;
    color: #2D3892;
  }

  &:focus {
    box-shadow: 0 0 0 0.2rem lighten(#FBE7D0, 3px) !important;
  }
}

.btn-subscribe {
  padding: 10px;
  border: 1px solid #FFFFFF;
  border-radius: 5px;
  background-color: #FFFFFF;
  color: black;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;

  &:hover {
    background-color: $light;
  }

  &.disabled, &:disabled {
    color: $gray-400;
    background-color: $gray-5;
    cursor:not-allowed
  }
}

@media (max-width: 500px) {
  .btn {
    font-size: 13px;  }
}
